import React from 'react';
import { Carousel } from 'antd'; 
import { Link, graphql } from 'gatsby'
import SiteLayout from '@layouts/site-layout';
 

export default class InfoPage extends React.Component {
    render() {
        return (
            <SiteLayout>  
                 
                <div className="top_margin_comn">
                    <div className="comn_bkgimg home_slider_sec" 
                    style={{background:"url('../img/abt_banner_slide.jpg') no-repeat center center /cover", color:"#fff"}}
                    > 
                        <div className="container">
                            <h3>Privacy Policy</h3>
                        </div>
                    </div>
                </div>

                    <div className="container"> 
                        <div className="term_condition">
                            <p>&nbsp;</p>
                            <h2>Key Information</h2>
                            <p>STEPapp, is the data controller of the personal information we collect about you (i.e., the entity that determines the means and purposes of collecting, using, and disclosing the personal information), unless you are part of a degree, certain programs, or certain other circumstances as communicated to you, in which case STEPapp is the data processor.</p>
                            <p>We collect the personal information set out in the "What Information We Collect" section of this Privacy Notice below, including account registration details such as name and email, details of Content Offerings you undertake, survey information (where you provide it), identity verification data, and information about your use of our site and Services.</p>
                            <p>We use your personal information for the purposes set out in the "How We Use the Information" section of this Privacy Notice below, including providing our site and Services to you, ensuring the security and performance of our site, conducting research relating to Content Offerings, sharing information with our Content Providers and our suppliers, direct marketing, and performing statistical analysis of the use of our site and Services.</p>
                            <p>You have a number of rights that you can exercise in relation to our use of your personal information, as set out in the "Updating or Deleting Your Personally Identifiable Information" section of this Privacy Notice below.</p>

                            <h2>Privacy Policy</h2>
                            <p>Your privacy is important to STEPapp, our subsidiaries, and our international branches, ("STEPapp," "us," "we," or "our"). This Privacy Policy covers how we collect, use, share, transfer, and store information that we receive when you use our interface which includes STEPapp &  www. ___ (hereinafter referred to as “SITE” (your use of Site will be also governed by our “Terms of use”). Please take a moment and read over our privacy policy carefully. This Privacy Policy applies only to those Sites, services, and applications included within “Services” and doesn’t apply to any third-party websites, services, or applications, even if they are accessible through our Services. Also, please note that, unless we define a term in this Privacy Policy, all capitalized words used in this Privacy Policy have the same meanings as in our Terms of Service. For the sake of brevity, your use of our App’ in any electronic form or device shall be bound by the terms and conditions enumerated and agreed upon hereunder with willful and free consent. Our primary goals in collecting information are to provide and improve our Services, to administer your use of the Services (including your Account, if you are an Account holder), and to enable you to enjoy and easily navigate our Services.</p> 

                            <h2>What Information this Privacy Policy Covers</h2>
                            <p>This Privacy Policy covers information we collect from you through our Site. Some of our Site’s functionality can be used without revealing any Personal Data, but for features or Services related to the Content Offerings, Personal Data is required. In order to access certain features and benefits on our Site, you may need to submit, or we may collect, <strong>"Personal Data"</strong> (i.e., information that can be used to identify you and which may also be referred to as “personally identifiable information” or “personal information”). Personal Data can include information such as your name, email address, IP address, and device identifier, among other things. You are responsible for ensuring the accuracy of the Personal Data you submit to STEPapp. Inaccurate information may affect your ability to use the Services, the information you receive when using the Site, and our ability to contact you. For example, your email address should be kept current because that is one of the primary manners in which we communicate with you.</p>

                            <h2>Collection and Use of PII (Personally Identifiable Information) How we collect and use information:</h2>
                            <p>When you register we may collect a variety of information, including your name, address, phone number, email address, contact preferences, and credit card information (“Personally Identifiable Information” or “PII”) and could fall under the categories of “Personal Information“, “Sensitive Personal Information” and “Associated Information“. Personal Information, Sensitive Personal Information, and Associated Information (each as individually defined under this Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 (the “Data Protection Rules“) shall collectively be referred to as “Information” in this Policy. We may collect the information you provide us with such as your gender and age range preferences, search distance preferences, and information about your interests while using the app or online game. If you chat with other STEPapp users you provide us the content of your chats and if you contact our Support staff or any STEPapp staff member you provide us with the content of that communication. STEPapp can decide that the content of that communication is in some way inappropriate and terminate the user's account. Stepapp may, but is not obligated to monitor or review any content you post as a part of the service.</p>

                            <h2>Cookie policy:</h2>
                            <p>We may use cookies to identify that you’ve logged in to the Services. STEPapp App sends cookies (small files containing a string of characters) to your computer, thereby uniquely identifying your browser. Cookies are used to track your preferences, help users log in faster, and are aggregated to determine user trends. This data is used to improve its offerings, such as providing more content in areas of greater interest to a majority of users. Although most browsers automatically accept cookies, you can change your browser options/settings to stop automatically accepting cookies or to prompt you before accepting cookies. Please note, however, that if you don’t accept cookies, you will not be able to stay automatically logged in to the Services.</p>

                            <h2>How we use your PII</h2>
                            <p>The PII we collect allows us to keep you posted on STEPapp latest Services and updates. STEPapp and its affiliates may share PII with each other and use it consistent with this Privacy Policy. They may also combine it with other information to provide and improve our Services. We also use PII to help us develop, deliver, and improve our Services. From time to time, we may use your PII to send important notices, such as communications about purchases and changes to our terms, conditions, and policies. If you don’t want to be on our mailing list, you can opt-out anytime by unregistering at unsubscribe@Stepapp.ai We may also use PII for internal purposes such as auditing, data analysis, and research to improve STEPapp Services and customer communications. We may use PII to develop, display, and track content and advertising specific to your interests expressed using the services and other sites, including providing our advertisements to you when you visit other sites. We may use PII to verify your eligibility and deliver prizes in connection with contests, sweepstakes, and promotions. We may use PII to enforce or exercise any rights in our Terms of Use and perform functions or services as otherwise described to you at the time of collection.</p>
                            <p>We further use your PII to determine your general geographic location, provide localized courses and classes,  provide you with customized and personalized study material, recommendations, determine your internet service provider, and help us quickly and efficiently respond to inquiries and requests and enforce our terms (such as determining free trial eligibility)  and communicate with you concerning our service (for example by email, push notifications, text messaging, and online messaging channels), so that we can send you details about new features and content available on the Website, special offers,  promotional announcements, surveys, and to assist you with operational requests such as password reset requests.</p>
                            <p>If you are an employee, we use your Personal Information for purposes of payrolling and recording attendance, deduction of taxes, payment of remuneration, and providing employee benefits (including making contributions to the provident fund, procuring medical insurance, etc.).</p>
                            <p>If you are a vendor/supplier, we use your Personal Information and for the purpose of making payments and deducting taxes.</p>

                            <h2>Collection and Use of Non-PII</h2>
                            <p>We also collect non-PII − data in a form that does not permit direct association with any specific individual. We may collect, use, transfer, and disclose non-PII for any purpose. We may collect information such as occupation, language, zip code, area code, unique device identifier, location, and the time zone where the Services are used so that we can better understand customer behavior and improve our Services. If we do combine non-PII with PII the combined information will be treated as PII for as long as it remains combined for the safety of said information. STEPapp would be liable to disclose the information it is in possession of in the following situations: as required by law, such as to comply with a subpoena or similar legal process; to enforce applicable ToU, including investigation of potential violations thereof; when it believes in good faith (doctrine of uberrima fides) that the disclosure is necessary to protect its rights, protect your safety or the safety of others, investigate fraud, address security or technical issues or respond to a government request; with its trusted services providers who work on its behalf, do not have an independent use of the information Stepapp App discloses to them, and have agreed to and adhered to the rules set forth in this Policy;- to protect against imminent harm to the rights, property or safety of the Application/Website/STEPapp Technologies Private Limited or its users or the public as required or permitted by law.</p>

                            <h2>Disclosure to Third Parties</h2>
                            <p>At times STEPapp may make certain PII available to strategic partners that work with STEPapp to provide products and services, or that help STEPapp market to customers. For example, when you make a purchase through the Apps, you authorize STEPapp and its third-party payment processor to exchange the payment information you provide to us to carry out your purchase on the Services. PII will only be shared by STEPapp to provide or improve our Services; it will not be shared with third parties for their marketing purposes.</p>

                            <h2>Service Providers</h2>
                            <p>STEPapp shares PII as required, with companies who provide services such as hosting, information processing, extending credit, fulfilling customer orders, delivering products to you, managing and enhancing customer data, providing customer service, assessing your interest in our products and services, and conducting customer research or satisfaction surveys.</p>

                            <h2>Public Forums</h2>
                            <p>When you use certain features on its website like the discussion forums and you post or share your personal information such as comments, messages, files, and photos, will be available to all users, and will be in the public domain. All such sharing of information is done at your own risk. Please keep in mind that if you disclose personal information in your profile or when posting on its forums this information may become publicly available.</p>

                            <h2>Others</h2>
                            <p>It may be necessary − by law, legal process, litigation, and/or requests from public and governmental authorities within or outside your country of residence − for STEPapp to disclose your PII. We may also disclose information about you if we determine that for purposes of national security, law enforcement, or other issues of public importance, disclosure is necessary or appropriate. We may also disclose information about you if we determine that disclosure is reasonably necessary to enforce our terms and conditions or protect our operations or users. Additionally, in the event of a reorganization, merger, or sale we may transfer any and all PII we collect to the relevant third party.</p>

                            <h2>Protection of PII</h2>
                            <p>STEPapp takes precautions — including administrative, technical, and physical measures — to safeguard your PII against loss, theft, and misuse, as well as against unauthorized access, disclosure, alteration, and destruction. When you use STEPapp Apps or post on a chat room or social networking service, the PII you share is visible to other users and can be read, collected, or used by them. You are solely responsible for the PII you choose to submit in these instances. For example, if you list your name and email address in a forum posting, that information is public. Please take care when using these features.</p>

                            <h2>Integrity and Retention of PII</h2>
                            <p>We will retain your PII for the period necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period for compliance with requirements prescribed by the law or voluntary codes of conduct is necessary.</p>

                            <h2>Access to PII</h2>
                            <p>For other PII, we make good faith efforts to provide you with access to such PII so you can request that we correct or delete the PII we have about you. We may decline to process requests that are unreasonably repetitive, require disproportionate technical effort, jeopardize the privacy of others, are extremely impractical, or for which access is not otherwise required by local law. Access, correction, or deletion requests can be made by contacting Stepapp at <a href="mailto:support@stepapp.ai">support@stepapp.ai</a></p>

                            <h2>Children</h2>
                            <p>We do not knowingly collect PII from children under 13. If we learn that we have collected the PII of a child under 13, we will take steps to delete the information as soon as possible.</p>

                            <h2>Location-Based Services</h2>
                            <p>In some cases, we collect and store information about where you are located, such as by converting your IP address into a rough geolocation. We may use location information to improve and personalize our Services for you. For you to be able to discover new friends that share your interests using the “people” feature you will need to give STEPapp Location Service permission. You can give STEPapp permission to use your location services at a later time by going to Settings -> STEPapp -> Privacy -> Location Services ->Allow Location Access. That way you will be able to discover people around you and other people will be able to discover you.</p>

                            <h2>International Users</h2>
                            <p>Information you provide may be transferred or accessed by entities around the world as described in this Privacy Policy. If you’re located outside India and choose to provide your PII to us, we may transfer your PII to India and process it there. Your use of the Services followed by your submission of any PII represents your agreement to that transfer.</p>

                            <h2>Our Companywide Dedication & Commitment to Your Privacy</h2>
                            <p>We take reasonable measures and make it our priority to protect the information that we collect from or about you (including your PII) from unauthorized access, use, or disclosure. To this effect, physical, electronic, and procedural safeguards are put in place to protect the Information that is processed & maintained. Please be aware, however, that no method of transmitting information over the Internet or storing information is completely secure. Accordingly, we cannot guarantee the absolute security of any information. STEPapp will, at all times, provide the option to you, not to provide the Personal Information or Sensitive Personal Information, which it seeks from you. Further, you can, at any time while using the Application/Services/products, also have an option to withdraw your consent given earlier to it, to use such Personal Information or Sensitive Personal Information. Withdrawal of the consent by you is required to be sent in writing to us at the contact details provided in this Policy below. In any such event, STEPapp Technologies Private Limited fully reserves the right to withdraw further usage of the Application/Website or provide any further Services/products thereunder to you.</p>

                            <h2>Permissions and Consent:</h2>
                            <p>STEPapp believes that every user of our Application/Services/Products/Website must be in a position to provide informed consent prior to providing any Information required for the use of the Application/Services/Products/Website. By registering with it, you are expressly consenting to its collection, processing, storing, disclosing, and handling of your information as set forth in this Policy now and as amended by us. Processing, your information in any way, including, but not limited to, collecting, storing, deleting, using, combining, sharing, transferring, and disclosing information, all of which activities will take place in India. If you reside outside India your information will be transferred, processed, and stored in accordance with the applicable data protection laws of India. STEPapp may update its Privacy Policy from time to time. Any information that we collect is subject to the privacy policy in effect at the time such information is collected. When we change the policy in a material way, we’ll notify you of such changes by posting them on the Services or by sending you an email or other notification, and we’ll indicate when such changes will become effective. A notice will be posted on our website along with the updated Privacy Policy.</p>

                            <h2>Grievance office</h2>
                            <p>Any discrepancies and grievances with respect to the processing of PII shall be informed to the designated Grievance Officer as mentioned below:</p>
                            <p>Name   : <strong>Mr. Parth Sheth</strong></p>
                            <p>Designation : <strong>IT Head</strong></p>
                            <p>Email ID      : <a href="mailto:support@stepapp.ai">support@stepapp.ai</a></p>
                        </div>
                    </div>            
            </SiteLayout>
        )
    }
}
